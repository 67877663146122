<template>
  <div>
    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <b-row>
        <b-col lg="9">
          <b-card>
            <validation-observer ref="simpleRules">
              <b-form class="ml-1 mr-1 mt-1">
                <b-row>
                  <b-col md="4">
                    <b-form-group
                      label="F. Emisión: "
                    >
                      <b-input-group>
                        <b-form-datepicker
                          id="datepicker-dateformat1"
                          v-model="data.fecha_emision"
                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          :max="data.fecha_vcto"
                          locale="es"
                          @input="seleccionarFechaEmision"
                        />
                        <!-- <b-form-input
                          v-model="compraData.pa_fecha_vencimiento"
                          type="date"
                        /> -->
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="F. Vencimiento: "
                    >
                      <b-input-group>
                        <b-form-datepicker
                          id="datepicker-dateformat2"
                          v-model="data.fecha_vcto"
                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          :min="data.fecha_emision"
                          locale="es"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="3">
                    <b-form-group
                      label="Tipo Comprobante: "
                    >
                      <b-input-group>
                        <b-form-select
                          v-model="data.tipo_doc"
                          class="form-control"
                          :options="tipos"
                          @change="seleccionarTipo"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group
                      label="Serie: "
                    >
                      <b-input-group>
                        <b-form-select
                          v-model="data.serie"
                          class="form-control"
                          @change="seleccionarSerie"
                        >
                          <option
                            v-for="option in series"
                            :key="option.value"
                          >
                            {{ option.text }}
                          </option>
                        </b-form-select>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group
                      label="Moneda: "
                    >
                      <b-input-group>
                        <b-form-select
                          v-model="data.moneda"
                          class="form-control"
                          :options="monedas"
                          @change="seleccionarMoneda"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group
                      label="Tipo de cambio: "
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Cambio"
                        rules="required"
                      >
                        <b-form-input
                          v-model="data.tipo_cambio"
                          type="number"
                          @keypress="isNumber($event)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Nota de credito -->
                <b-row
                  v-if="data.tipo_doc == '07'"
                >
                  <b-col sm="3">
                    <b-form-group
                      label="Documento Relacionado: "
                    >
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        class="form-control"
                        @click="showModalNotaCredito"
                      >
                        <feather-icon
                          icon="SearchIcon"
                        />
                        <span class="align-middle">Buscar</span>
                      </b-button>
                    </b-form-group>
                  </b-col>
                  <b-col sm="2">
                    <b-form-group
                      label="Serie: "
                    >
                      <b-form-input
                        v-model="data.serie_relacionado"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="2">
                    <b-form-group
                      label="Correlativo: "
                    >
                      <b-form-input
                        v-model="data.correlativo_relacionado"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      label="Motivo: "
                    >
                      <b-input-group>
                        <b-form-select
                          v-model="data.codigo_nota_credito"
                          class="form-control"
                          :options="motivos"
                          @change="seleccionarMotivo"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="8">
                    <b-form-group
                      label="Cliente: "
                    >
                      <b-input-group>
                        <v-select
                          v-model="selectedCliente"
                          label="cRazonSocial"
                          style="width: 90%;"
                          :options="clientes"
                          :filter-by="myFilter"
                          @input="changeValue($event)"
                          @keyup.native.enter="busquedaChange($event)"
                          @search="onSearch"
                        >
                          <span slot="no-options">Sin resultados.</span>
                          <template>
                            <b-spinner label="Loading..." />
                          </template>
                          <template v-slot:selected-optio n="option">
                            {{ option.cNumeroRuc }} - {{ option.cRazonSocial }}
                          </template>
                          <template v-slot:option="option">
                            {{ option.cNumeroRuc }} - {{ option.cRazonSocial }}
                          </template>
                        </v-select>
                        <b-button
                          v-if="selectedCliente"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          style="width: 10%;"
                          class="btn-icon"
                          @click="showModal"
                        >
                          <feather-icon icon="SearchIcon" />
                        </b-button>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="selectedCliente"
                    md="4"
                  >
                    <b-form-group
                      label="Dirección"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Direccion"
                        rules="required"
                      >
                        <b-form-input
                          v-model="data.direccion_cliente"
                          placeholder="Direccion"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="2">
                    <b-form-group label="¿Detracción?">
                      <b-form-checkbox
                        v-model="data.is_detraccion"
                        checked="false"
                        name="check-button"
                        switch
                        inline
                        @change="cambioDetraccion"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="data.is_detraccion"
                    md="2"
                  >
                    <b-form-group
                      label="Porcentaje (%): "
                    >
                      <b-input-group>
                        <b-form-input
                          v-model="data.porcentaje_detraccion"
                          type="number"
                          placeholder="% Detracción"
                          @keypress="isNumber($event)"
                          @blur="porc_detraccion"
                        />
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          class="btn-icon"
                          @click="showModalDetracciones"
                        >
                          <feather-icon icon="SearchIcon" />
                        </b-button>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="data.is_detraccion"
                    md="2"
                  >
                    <b-form-group
                      label="Total: "
                    >
                      <b-form-input
                        v-model="data.detrac_total"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="data.is_detraccion"
                    md="3"
                  >
                    <b-form-group
                      label="Nro. Cuenta: "
                    >
                      <b-form-input
                        v-model="data.constancia_detraccion"
                        type="number"
                        @keypress="isNumberInt($event)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="data.is_detraccion"
                    md="3"
                  >
                    <b-form-group
                      label="Fecha: "
                    >
                      <b-form-datepicker
                        id="datepicker-detraccion"
                        v-model="data.fecha_detraccion"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        locale="es"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="2">
                    <b-form-group label="¿Retención?">
                      <b-form-checkbox
                        v-model="data.is_retencion"
                        checked="false"
                        name="check-button"
                        switch
                        inline
                        @change="cambioRetencion"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="data.is_retencion"
                    md="2"
                  >
                    <b-form-group
                      label="Porcentaje: "
                    >
                      <b-form-input
                        v-model="data.reten_porc"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="data.is_retencion"
                    md="2"
                  >
                    <b-form-group
                      label="Total: "
                    >
                      <b-form-input
                        v-model="data.monto_retencion"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-table
                      :sticky-header="true"
                      striped
                      responsive="sm"
                      :fields="fields"
                      :items="detallevista"
                    >
                      <template
                        v-if="data.is_anticipo"
                        v-slot:cell(det_descripcion)="row"
                      >
                        <b-form-input
                          v-model="row.item.det_descripcion"
                          type="text"
                          @blur="calcularDetalle(row.item)"
                        />
                      </template>
                      <template
                        v-if="data.is_anticipo"
                        v-slot:cell(det_valor_unitario)="row"
                      >
                        <b-form-input
                          v-model="row.item.det_valor_unitario"
                          type="number"
                          @keypress="isNumber($event)"
                          @blur="calcularDetalleUnitario(row.item)"
                        />
                      </template>
                      <template
                        v-if="data.is_anticipo"
                        v-slot:cell(det_precio)="row"
                      >
                        <b-form-input
                          v-model="row.item.det_precio"
                          type="number"
                          @keypress="isNumber($event)"
                          @blur="calcularDetalle(row.item)"
                        />
                      </template>
                    </b-table>
                  </b-col>
                </b-row>
                <b-row class="invoice-padding pb-0">
                  <b-col
                    cols="12"
                    md="12"
                    class="mt-md-12 d-flex justify-content-end"
                    order="1"
                    order-md="6"
                  >
                    <div class="invoice-total-wrapper">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          {{ data.is_deduccion? 'Gravado Bruto:':'Gravado:' }}
                        </p>
                        <p class="invoice-total-amount">
                          {{ data.monto_gravado }}
                        </p>
                      </div>
                      <div v-if="data.is_deduccion" class="invoice-total-item">
                        <p class="invoice-total-title">
                          Anticipo:
                        </p>
                        <p class="invoice-total-amount">
                          {{ - data.monto_gravado_deduccion }}
                        </p>
                      </div>
                      <hr v-if="data.is_deduccion" class="my-50">
                      <div v-if="data.is_deduccion" class="invoice-total-item">
                        <p class="invoice-total-title">
                          Gravado Neto:
                        </p>
                        <p class="invoice-total-amount">
                          {{ gravadoTotal }}
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          IGV:
                        </p>
                        <p class="invoice-total-amount">
                          {{ data.monto_igv }}
                        </p>
                      </div>
                      <hr class="my-50">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Total:
                        </p>
                        <p class="invoice-total-amount">
                          {{ data.monto_total }}
                        </p>
                      </div>
                    </div>
                  </b-col>
                  <!-- <b-col
                    md="3"
                    class="text-md-right"
                  >
                    <b-form-group label="Total: " />
                  </b-col>
                  <b-col md="3">
                    <b-form-input
                      v-model="data.monto_total"
                      disabled
                      class="text-md-right"
                    />
                  </b-col> -->
                </b-row>
                <b-row class="text-center">
                  <b-col
                    md="12"
                    class="mb-1"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-1"
                      @click.prevent="validationForm"
                    >
                      <feather-icon
                        icon="SaveIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">Guardar</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-col>
        <b-col lg="3">
          <b-card>
            <b-row>
              <b-col sm="12">
                <b-form-group
                  label="Pago: "
                >
                  <b-input-group>
                    <b-form-select
                      v-model="data.tipo_pago"
                      class="form-control"
                      :options="pago"
                      @change="seleccionarTipopago"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col
                v-if="data.tipo_pago=='CREDITO'"
                sm="12"
              >
                <table style="width: 100%;">
                  <thead>
                    <tr>
                      <th
                        class="text-left"
                        style="width: 100px"
                      >
                        Fecha
                      </th>
                      <th
                        class="text-left"
                        style="width: 60px"
                      >
                        Monto
                      </th>
                      <th style="width: 30px" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(row, index) in data.cuotas"
                      :key="index"
                    >
                      <td>
                        <b-input-group>
                          <b-form-datepicker
                            v-model="row.cuo_fecha"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            :min="data.fecha_emision"
                            locale="es"
                          />
                        </b-input-group>
                      </td>
                      <td>
                        <b-form-input
                          v-model="row.cuo_monto"
                          type="number"
                          placeholder="Monto"
                          @keypress="isNumber($event)"
                        />
                      </td>
                      <td>
                        <b-button
                          variant="gradient-danger"
                          class="btn-icon"
                          @click="remove(index)"
                        >
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
              <b-col
                v-if="data.tipo_pago=='CREDITO'"
                sm="12"
                class="mt-2"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="clickAddFee"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-25"
                  />
                  <span>Agregar cuota</span>
                </b-button>
              </b-col>
              <b-col
                v-if="data.tipo_doc == '01'"
                md="9"
                class="mt-2"
              >
                ¿Es un pago anticipado?
              </b-col>
              <b-col
                v-if="data.tipo_doc == '01'"
                md="3"
                class="mt-2"
              >
                <b-form-checkbox
                  v-model="data.is_anticipo"
                  checked="false"
                  name="check-button"
                  switch
                  inline
                  @change="cambioAnticipo"
                />
              </b-col>
              <b-col
                v-if="data.tipo_doc == '01'"
                md="9"
                class="mt-2"
              >
                ¿Deducción de anticipo?
              </b-col>
              <b-col
                v-if="data.tipo_doc == '01'"
                md="3"
                class="mt-2"
              >
                <b-form-checkbox
                  v-model="data.is_deduccion"
                  checked="false"
                  name="check-button"
                  switch
                  inline
                  @change="cambioDeduccion"
                />
              </b-col>
              <b-col
                v-if="data.is_deduccion"
                class="mt-2"
                md="12"
              >
                <b-input-group>
                  <b-form-select
                    v-model="tipo_deduccion"
                    :options="tiposdeduccion"
                  />
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    style="width: 15%;"
                    class="btn-icon"
                    @click="showModalDeduccion"
                  >
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </b-input-group>
              </b-col>
              <b-col
                v-if="selectedAnt[0]"
                class="mt-2"
                sm="6"
              >
                <b-form-group
                  label="Serie: "
                >
                  <b-form-input
                    v-model="data.serie_deduccion"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="selectedAnt[0]"
                class="mt-2"
                sm="6"
              >
                <b-form-group
                  label="Correlativo: "
                >
                  <b-form-input
                    v-model="data.correlativo_deduccion"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <app-collapse>
                  <app-collapse-item title="Información adicional">
                    <label>Documento Relacionado</label>
                    <b-form-tags
                      v-model="doc_relacionado"
                      input-id="tags-basic"
                      class="mb-2"
                      placeholder="Agregar documento"
                    />
                    <label>Nro. de Operación</label>
                    <b-form-input
                      v-model="data.nro_operacion"
                      placeholder="Nro. de Operación"
                      class="mb-2"
                    />
                    <label>Observaciones</label>
                    <b-form-textarea
                      v-model="data.observacion"
                      placeholder="Observaciones"
                      rows="3"
                    />
                  </app-collapse-item>
                </app-collapse>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
    <!-- modal -->
    <b-modal
      ref="my-modal"
      ok-variant="secondary"
      ok-title="Agregar"
      cancel-variant="outline-secondary"
      size="lg"
      modal-class="modal-secondary"
      centered
      title="Seleccionar"
      @ok="agregarDetalle"
    >
      <b-card-text>
        <b-table
          :sticky-header="true"
          striped
          responsive="sm"
          :fields="fieldOrder"
          :items="ordenes"
          selectable
          select-mode="multi"
          @row-selected="onRowSelectedOrder"
        >
          <template #cell(row)="data">
            <div style="width:0px !important;">
              <b-form-checkbox
                disabled
                :checked="selectedOrdenes.arrayId.includes(data.item)"
              />
            </div>
          </template>
        </b-table>
      </b-card-text>
    </b-modal>
    <b-modal
      ref="modal-detrac"
      ok-variant="secondary"
      ok-title="Agregar"
      cancel-variant="outline-secondary"
      size="lg"
      modal-class="modal-secondary"
      centered
      title="Seleccionar Detracción"
      @ok="seleccionarDetraccion"
    >
      <b-card-text>
        <b-table
          ref="selectableTable"
          :sticky-header="true"
          striped
          responsive="sm"
          :fields="fieldDetracciones"
          :items="detracciones"
          selectable
          :select-mode="'single'"
          @row-selected="onRowSelected"
        >
          <template #cell(selected)="data">
            <div style="width:0px !important;">
              <b-form-checkbox
                disabled
                :checked="selectedDetraccion.includes(data.item)"
              />
            </div>
          </template>
          <!-- <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
            </template>
          </template> -->
        </b-table>
      </b-card-text>
    </b-modal>
    <!-- ModalNota -->
    <b-modal
      ref="modal-notacredito"
      ok-variant="secondary"
      ok-title="Agregar"
      cancel-variant="outline-secondary"
      size="lg"
      modal-class="modal-secondary"
      centered
      title="Buscar Documento"
      @ok="seleccionarNotaCredito"
    >
      <b-card-text>
        <b-row>
          <b-col md="3">
            <b-form-group
              label="Tipo Comprobante: "
            >
              <b-input-group>
                <b-form-select
                  v-model="find.tipodoc"
                  class="form-control"
                  :options="tipos"
                  @change="seleccionarTipoNota"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Serie: "
            >
              <b-input-group>
                <b-form-select
                  v-model="find.serie"
                  class="form-control"
                >
                  <option
                    v-for="option in seriesNota"
                    :key="option.value"
                  >
                    {{ option.text }}
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Correlativo: "
            >
              <b-form-input
                v-model="find.correlativo"
                type="number"
                placeholder="Correlativo"
                @keypress="isNumberInt($event)"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label=""
              class="mt-1"
            >
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="form-control mt-1"
                @click="findDocuments"
              >
                <feather-icon
                  icon="SearchIcon"
                />
                <span class="align-middle">Buscar</span>
              </b-button>
            </b-form-group>
            <!-- <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="form-control mt-2"
              @click="findDocuments"
            >
              <feather-icon
                icon="SearchIcon"
              />
              <span class="align-middle">Buscar</span>
            </b-button> -->
          </b-col>
        </b-row>
        <b-table
          :sticky-header="true"
          striped
          responsive="sm"
          :fields="fieldDoc"
          :items="documents"
          selectable
          select-mode="single"
          @row-selected="onRowSelectedDoc"
        >
          <template #cell(row)="data">
            <div style="width:0px !important;">
              <b-form-checkbox
                disabled
                :checked="selectedDoc.includes(data.item)"
              />
            </div>
          </template>
        </b-table>
      </b-card-text>
    </b-modal>
    <b-modal
      ref="modal-deduccion"
      ok-variant="secondary"
      ok-title="Agregar"
      cancel-variant="outline-secondary"
      size="lg"
      modal-class="modal-secondary"
      centered
      title="Buscar Documento"
      @ok="seleccionarAnticipo"
    >
      <b-card-text>
        <b-row>
          <b-col md="3">
            <b-form-group
              label="Tipo Comprobante: "
            >
              <b-input-group>
                <b-form-select
                  v-model="find.tipodoc"
                  class="form-control"
                  :options="tipos"
                  @change="seleccionarTipoNota"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Serie: "
            >
              <b-input-group>
                <b-form-select
                  v-model="find.serie"
                  class="form-control"
                >
                  <option
                    v-for="option in seriesNota"
                    :key="option.value"
                  >
                    {{ option.text }}
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Correlativo: "
            >
              <b-form-input
                v-model="find.correlativo"
                placeholder="Correlativo"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="form-control mt-2"
              @click="findAnticipo"
            >
              <feather-icon
                icon="SearchIcon"
              />
              <span class="align-middle">Buscar</span>
            </b-button>
          </b-col>
        </b-row>
        <b-table
          :sticky-header="true"
          striped
          responsive="sm"
          :fields="fieldDoc"
          :items="documents"
          selectable
          select-mode="single"
          @row-selected="onRowSelectedAnt"
        >
          <template #cell(row)="data">
            <div style="width:0px !important;">
              <b-form-checkbox
                disabled
                :checked="selectedAnt.includes(data.item)"
              />
            </div>
          </template>
        </b-table>
      </b-card-text>
    </b-modal>
    <b-modal
      ref="modalPdf"
      v-model="showDialogOptions"
      size="lg"
      modal-class="modal-secondary"
      centered
      hide-footer
      @hide="doSometing"
    >
      <object
        style="width:100%; height:842pt"
        type="application/pdf"
        :data="`data:application/pdf;base64,${[base64]}`"
      />
    </b-modal>
  </div>
  <!-- modal -->
</template>

<script>
import {
  BRow, BCol, BCard, BForm, BFormGroup, BInputGroup, BFormInput, BFormSelect, BTable, BModal, VBModal, BCardText, BFormDatepicker, BButton, BFormCheckbox, BFormTextarea, BSpinner, BOverlay, BFormTags,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
// eslint-disable-next-line import/no-unresolved
import generalData from '@fakedata'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import store from '@/store/index'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BTable,
    BModal,
    BCardText,
    BFormDatepicker,
    BButton,
    BFormCheckbox,
    AppCollapse,
    AppCollapseItem,
    BFormTextarea,
    BSpinner,
    BOverlay,
    BFormTags,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      myFilter: (option, label, search) => {
        const temp = search.toLowerCase()
        return option.cRazonSocial.toLowerCase().indexOf(temp) > -1 || option.cNumeroRuc.toLowerCase().indexOf(temp) > -1
      },
      gravadoTotal:0,
      data: {
        serie: '',
        correlativo: 0,
        tipo_doc: generalData.venta.comprobantes[0].value,
        tipo_operacion: '10',
        moneda: generalData.venta.monedas[0].value,
        tipo_pago: generalData.venta.pago[0].value,
        fecha_emision: moment().format('yyyy-MM-DD'),
        fecha_vcto: moment().format('yyyy-MM-DD'),
        monto_gravado: 0,
        monto_gravado_deduccion:0,
        monto_exonerado: 0,
        monto_igv: 0,
        monto_total: 0,
        razon_social_cliente: '',
        direccion_cliente: '',
        doi_cliente: '',
        correo_cliente: '',
        tipo_doi_cliente: '',
        descuento: 0,
        estado: 'G',
        observacion: '',
        is_anticipo: false,
        monto_retencion: 0,
        porcentaje_detraccion: 0,
        constancia_detraccion: '',
        fecha_detraccion: '',
        cod_producto_detraccion: '',
        serie_relacionado: '',
        tipo_doc_relacionado: '',
        correlativo_relacionado: '',
        motivo_nota_credito: '',
        codigo_nota_credito: '',
        detail: [],
        cuotas: [],
        tipo_cambio: 1,
        is_detraccion: false,
        detrac_total: 0,
        is_retencion: false,
        reten_porc: 3,
        is_deduccion: false,
        arrayId: [],
        serie_deduccion: '',
        correlativo_deduccion: '',
        anexo: '',
        doc_relacionado: '',
        nro_operacion: '',
      },
      doc_relacionado: [],
      tipos: generalData.venta.comprobantes,
      series: [],
      seriesAll: [],
      seriesAll2: [],
      seriesNota: [],
      detallevista: [],
      motivos: generalData.venta.notacredito,
      monedas: generalData.venta.monedas,
      tipo_deduccion: generalData.venta.tipodeduccion[0].value,
      tiposdeduccion: generalData.venta.tipodeduccion,
      clientes: [],
      pago: generalData.venta.pago,
      detracciones: generalData.venta.detracciones,
      selectedDetraccion: [],
      showLoading: true,
      ordenes: [],
      inputSearch: '',
      selectedOrdenes: {
        arrayId: [],
      },
      find: {
        serie: '',
        correlativo: null,
        tipodoc: '',
      },
      documents: [],
      selectedDoc: [],
      selectedAnt: [],
      selectedCliente: null,
      showDialogOptions: false,
      base64: '',
      tittlePdf: '',
      fields: [
        { key: 'det_descripcion', label: 'Descripción', sortable: false },
        { key: 'det_cod_unidad_medida', label: 'Unidad', sortable: false },
        {
          key: 'det_cantidad', label: 'Cantidad', sortable: false, tdClass: 'text-right',
        },
        {
          key: 'det_valor_unitario', label: 'Valor Unitario', sortable: false, tdClass: 'text-right',
        },
        {
          key: 'det_precio', label: 'Precio Unitario', sortable: false, tdClass: 'text-right',
        },
        {
          key: 'det_subtotal', label: 'Subtotal', sortable: false, tdClass: 'text-right',
        },
      ],
      fieldOrder: [
        { key: 'row', label: '', sortable: false },
        { key: 'Cod_Interno', label: 'Código Interno', sortable: false },
        { key: 'FechaHora_Recepcion', label: 'Fecha Recepción', sortable: false },
      ],
      fieldDetracciones: [
        { key: 'selected', label: '', sortable: false },
        { key: 'codigo', label: 'Codigo', sortable: false },
        { key: 'text', label: 'Nombre', sortable: false },
        { key: 'porcentaje', label: 'Porcentaje', sortable: false },
      ],
      fieldDoc: [
        { key: 'row', label: '', sortable: false },
        { key: 'inv_serie', label: 'Serie', sortable: false },
        { key: 'inv_correlativo', label: 'Correlativo', sortable: false },
        { key: 'inv_estado', label: 'Estado', sortable: false },
        { key: 'inv_fecha_emision', label: 'Fecha Emision', sortable: false },
      ],
    }
  },
  mounted() {
    this.getInfoByID()
    this.showLoading = false
    // this.getCambioSunat()
  },
  methods: {
    validationForm() {
      // this.$refs.form.validate()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (this.data.serie === '') {
            this.sendMessage('Seleccione una serie', 'AlertCircleIcon', 'danger')
          } else if (this.data.razon_social_cliente === '') {
            this.sendMessage('Seleccione un cliente', 'AlertCircleIcon', 'danger')
          } else if (this.data.detail.length === 0) {
            this.sendMessage('Ingrese producto', 'AlertCircleIcon', 'danger')
          } else if (this.data.tipo_pago === 'CREDITO' && this.data.fecha_emision === this.data.fecha_vcto) {
            this.sendMessage('Fecha de vencimiento debe ser mayor a fecha de emisión', 'AlertCircleIcon', 'danger')
          } else if (this.data.tipo_pago === 'CREDITO' && this.data.cuotas.length === 0) {
            this.sendMessage('Ingrese al menos una cuota', 'AlertCircleIcon', 'danger')
          } else if (!this.comprobarFechas()) {
            this.sendMessage('Error en las fechas de sus cuotas', 'AlertCircleIcon', 'danger')
          } else if (!this.comprobarMontoCuota()) {
            this.sendMessage('Los montos de sus cuotas no concuerdan', 'AlertCircleIcon', 'danger')
          } else if (this.data.is_detraccion && this.data.porcentaje_detraccion === '') {
            this.sendMessage('Seleccione un código de detracción', 'AlertCircleIcon', 'danger')
          } else {
            this.data.doc_relacionado = this.doc_relacionado.join('|')
            this.Guardar()
          }
        }
      })
    },
    comprobarFechas() {
      if (this.data.tipo_pago === 'CREDITO') {
        if (this.data.fecha_vcto === this.data.cuotas[this.data.cuotas.length - 1].cuo_fecha) {
          return true
        }
        return false
      }
      return true
    },
    comprobarMontoCuota() {
      if (this.data.tipo_pago === 'CREDITO') {
        let suma = 0
        for (let i = 0; i < this.data.cuotas.length; i += 1) {
          suma += Number(this.data.cuotas[i].cuo_monto)
        }
        if (Number(suma) === Number(this.data.monto_total) - Number(this.data.detrac_total)) {
          return true
        }
        return false
      }
      return true
    },
    async Guardar() {
      this.showLoading = true
      const request = {
        url: '/api/invoices',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        data: this.data,
      }
      console.log(this.data)
      const resp = await store.dispatch('back/EXECUTE', request)
      console.log('respuesta', resp)
      if (resp.success === true) {
        this.sendMessage(resp.message, 'EditIcon', 'success')
        this.showLoading = false
        this.showDialogOptions = true
        this.base64 = resp.pdf
        this.initForm()
      } else {
        this.sendMessage(resp.message, 'AlertCircleIcon', 'danger')
        this.showLoading = false
        if(resp.codigo!="9999"){
          this.initForm()
        }
      }
    },
    initForm() {
      this.data.tipo_doc = generalData.venta.comprobantes[0].value
      this.filtrarSeries()
      this.data.correlativo = 0
      this.data.tipo_operacion = '10'
      this.data.moneda = generalData.venta.monedas[0].value
      this.data.tipo_pago = generalData.venta.pago[0].value
      this.data.fecha_emision = moment().format('yyyy-MM-DD')
      this.data.fecha_vcto = moment().format('yyyy-MM-DD')
      this.data.monto_gravado = 0
      this.data.monto_exonerado = 0
      this.data.monto_igv = 0
      this.data.monto_total = 0
      this.data.razon_social_cliente = ''
      this.data.direccion_cliente = ''
      this.data.doi_cliente = ''
      this.data.correo_cliente = ''
      this.data.tipo_doi_cliente = ''
      this.data.descuento = 0
      this.data.estado = 'G'
      this.data.observacion = ''
      this.data.is_anticipo = false
      this.data.monto_retencion = 0
      this.data.porcentaje_detraccion = 0
      this.data.constancia_detraccion = ''
      this.data.fecha_detraccion = ''
      this.data.cod_producto_detraccion = ''
      this.data.serie_relacionado = ''
      this.data.tipo_doc_relacionado = ''
      this.data.correlativo_relacionado = ''
      this.data.motivo_nota_credito = ''
      this.data.codigo_nota_credito = ''
      this.data.detail = []
      this.data.cuotas = []
      this.data.is_detraccion = false
      this.data.detrac_total = 0
      this.data.is_retencion = false
      this.data.reten_porc = 3
      this.data.is_deduccion = false
      this.data.serie_deduccion = ''
      this.data.correlativo_deduccion = ''
      this.data.anexo = ''
      this.selectedCliente = null
      this.selectedDetraccion = []
      this.selectedDoc = []
      this.selectedAnt = []
      this.detallevista = []
      this.data.nro_operacion = ''
      this.data.doc_relacionado = ''
      this.doc_relacionado = []
    },
    changeValue(e) {
      if (e) {
        if (this.data.tipo_doc === '01' && e.cNumeroRuc.length === 8) {
          this.sendMessage('Seleccione un cliente con RUC', 'AlertCircleIcon', 'danger')
          this.selectedCliente = null
        } else if (this.data.tipo_doc === '03' && e.cNumeroRuc.length === 11) {
          this.sendMessage('Seleccione un cliente con DNI', 'AlertCircleIcon', 'danger')
          this.selectedCliente = null
        } else {
            this.data.doi_cliente = e.cNumeroRuc
            this.data.razon_social_cliente = e.cRazonSocial
            this.data.direccion_cliente = e.cDireccion
            this.data.correo_cliente = e.cEmail
            if (e.cNumeroRuc.length === 11) {
              this.data.tipo_doi_cliente = '06'
            } else {
              this.data.tipo_doi_cliente = '01'
            }
            if(e.cNumeroRuc!="11111111"){
              this.data.detail = []
              this.detallevista = []
              this.data.monto_gravado = 0.00
              this.data.monto_total = 0.00
              this.data.monto_igv = 0.00
            }
        }
      } else {
        this.data.doi_cliente = ''
        this.data.razon_social_cliente = ''
        this.data.correo_cliente = ''
        this.data.direccion_cliente = ''
        this.data.detail = []
        this.detallevista = []
        this.data.monto_gravado = 0.00
        this.data.monto_total = 0.00
        this.data.monto_igv = 0.00
      }
    },
    async showModal() {
      this.showLoading = true
      await this.getOrderByCliente()
      this.showLoading = false
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
    showModalDetracciones() {
      this.$refs['modal-detrac'].show()
    },
    showModalNotaCredito() {
      this.$refs['modal-notacredito'].show()
    },
    showModalDeduccion() {
      this.$refs['modal-deduccion'].show()
    },
    async getOrderByCliente() {
      const request = {
        url: `/api/orders/byClient/${this.selectedCliente.iIdCliente}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
      const resp = await store.dispatch('back/EXECUTE', request)
      this.ordenes = resp
      for (let i = 0; i < this.ordenes.length; i += 1) {
        this.ordenes[i].FechaHora_Recepcion = moment.utc(this.ordenes[i].FechaHora_Recepcion).format('yyyy-MM-DD')
        // this.series.push("text": respRoles.series[i].serie.numero)
      }
    },
    agregarDetalle() {
      this.data.arrayId = []
      for (let i = 0; i < this.selectedOrdenes.arrayId.length; i += 1) {
        this.data.arrayId.push(this.selectedOrdenes.arrayId[i].Id_Pedido)
      }
      this.getOrderById()
    },
    async getOrderById() {
      const request = {
        url: '/api/orders/ordersByArrayIds',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        data: this.data,
      }
      const resp = await store.dispatch('back/EXECUTE', request)
      let suma = 0
      let gravado = 0
      let igv = 0
      this.data.detail = []
      this.detallevista = []
      var totalExceso = 0
      for (let i = 0; i < resp.length; i += 1) {
        this.data.detail.push(
          {
            det_descripcion: resp[i].Nom_Muestra + " "+ resp[i].Elemento_Analisis,
            det_codigo: resp[i].Nom_Muestra,
            det_cod_unidad_medida: 'NIU',
            det_cantidad: 1,
            det_valor_unitario: (resp[i].Precio_Final / 1.18).toFixed(2),
            det_igv: ((resp[i].Precio_Final) - (resp[i].Precio_Final / 1.18)).toFixed(2),
            det_precio: resp[i].Precio_Final.toFixed(2),
            det_aplicacion: 'GRAVADO',
          },
        )
        this.detallevista.push(
          {
            det_descripcion: resp[i].Nom_Muestra + " "+ resp[i].Elemento_Analisis,
            det_codigo: resp[i].Nom_Muestra,
            det_cod_unidad_medida: 'UND',
            det_cantidad: 1,
            det_valor_unitario: (resp[i].Precio_Final / 1.18).toFixed(2),
            det_igv: ((resp[i].Precio_Final) - (resp[i].Precio_Final / 1.18)).toFixed(2),
            det_precio: resp[i].Precio_Final.toFixed(2),
            det_subtotal: resp[i].Precio_Final.toFixed(2),
            det_aplicacion: 'GRAVADO',
          },
        )
        gravado += (resp[i].Precio_Final / 1.18)
        // igv += ((resp[i].Precio_Final) - (resp[i].Precio_Final / 1.18))
        // suma += resp[i].Precio_Final
        totalExceso += resp[i].Precio_Exceso
      }
      console.log("totalExceso",totalExceso)
      if(totalExceso>0){
        this.data.detail.push(
          {
            det_descripcion: "Excesos",
            det_codigo: "Excesos",
            det_cod_unidad_medida: 'NIU',
            det_cantidad: 1,
            det_valor_unitario: (totalExceso / 1.18).toFixed(2),
            det_igv: (totalExceso - (totalExceso / 1.18)).toFixed(2),
            det_precio: (totalExceso).toFixed(2),
            det_aplicacion: 'GRAVADO',
          },
        )
        this.detallevista.push(
          {
            det_descripcion: "Excesos",
            det_codigo: "Excesos",
            det_cod_unidad_medida: 'UND',
            det_cantidad: 1,
            det_valor_unitario: (totalExceso / 1.18).toFixed(2),
            det_igv: (totalExceso - (totalExceso / 1.18)).toFixed(2),
            det_precio: (totalExceso).toFixed(2),
            det_subtotal:(totalExceso).toFixed(2),
            det_aplicacion: 'GRAVADO',
          },
        )
        gravado += (totalExceso / 1.18)
        // igv += (totalExceso - (totalExceso / 1.18))
        // suma += totalExceso
      }
      // igv-=this.data.monto_gravado_deduccion
      console.log('gravado',gravado)
      console.log('this.data.monto_gravado_deduccion',this.data.monto_gravado_deduccion)
      var gravadoTotal = ((this.data.monto_gravado_deduccion>gravado)?0:(gravado - this.data.monto_gravado_deduccion))
      console.log('gravadoTotal',gravadoTotal)
      // gravadoTotal = Math.round(gravadoTotal*100)/100;
      this.gravadoTotal = gravadoTotal
      igv = gravadoTotal*0.18
      // igv = Math.round(igv*100)/100;
      suma = gravadoTotal + igv
      // suma = Math.round(suma*100)/100;
      this.data.monto_gravado = gravado.toFixed(2)
      this.data.monto_total = suma.toFixed(2)
      this.data.monto_igv = igv.toFixed(2)
      if (this.data.is_retencion) {
        this.data.reten_porc = 3
        this.data.monto_retencion = (this.data.monto_total * 3) / 100
      }
      // this.data.detail = resp
    },
    busquedaChange() {
      if (this.inputSearch.length >= 3) {
        this.getClientes(this.inputSearch)
      }
    },
    async onSearch(search) {
      this.inputSearch = search
      this.clientes = []
    },
    async getClientes(e) {
      console.log('e',e)
      const request = {
        url: `/api/clients/byFilter/${e}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
      const resp = await store.dispatch('back/EXECUTE', request)
      this.clientes = resp
    },
    async getInfoByID() {
      const request = {
        url: `/api/users/${JSON.parse(localStorage.getItem('userData')).id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
      const respRoles = await store.dispatch('back/EXECUTE', request)
      this.series = []
      for (let i = 0; i < respRoles.series.length; i += 1) {
        this.seriesAll.push(
          {
            value: respRoles.series[i].serie.id,
            text: respRoles.series[i].serie.numero,
            anexo: respRoles.series[i].serie.anexo,
          },
        )
      }
      const request2 = {
        url: '/api/series/',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
      const resp = await store.dispatch('back/EXECUTE', request2)
      this.seriesAll2 = []
      for (let i = 0; i < resp.length; i += 1) {
        this.seriesAll2.push(
          {
            value: resp[i].id,
            text: resp[i].numero,
          },
        )
      }
      this.filtrarSeries()
    },
    async getCambioSunat() {
      const request = {
        url: '/api/consulta/tipoCambio',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        data: {
          fecha: this.data.fecha_emision,
        },
      }
      const resp = await store.dispatch('back/EXECUTE', request)
      if (resp.success) {
        this.data.tipo_cambio = resp.data.compra
      } else {
        this.data.tipo_cambio = ''
        this.sendMessage('Ocurrió un error', 'AlertCircleIcon', 'danger')
      }
    },
    onPreviewClick(value, index, item) {
      let band = false
      for (let i = 0; i < this.selectedOrdenes.arrayId.length; i += 1) {
        if (this.selectedOrdenes.arrayId[i] === item.Id_Pedido) {
          this.$delete(this.selectedOrdenes.arrayId, i)
          band = true
        }
      }
      if (!band) {
        this.selectedOrdenes.arrayId.push(item.Id_Pedido)
      }
    },
    onRowSelectedOrder(items) {
      this.selectedOrdenes.arrayId = items
    },
    porc_detraccion() {
      this.data.detrac_total = parseFloat((this.data.monto_total*this.data.porcentaje_detraccion/100).toFixed(0))
    },
    seleccionarTipopago() {
      this.data.cuotas = []
      if (this.data.tipo_pago === 'CREDITO') {
        this.clickAddFee()
      }
    },
    remove(index) {
      this.data.cuotas.splice(index, 1)
    },
    clickAddFee() {
      if (this.data.cuotas.length === 0) {
        this.data.cuotas.push({
          cuo_fecha: moment().format('YYYY-MM-DD'),
          cuo_monto: this.data.monto_total - this.data.detrac_total,
        })
      } else {
        this.data.cuotas.push({
          cuo_fecha: moment().format('YYYY-MM-DD'),
          cuo_monto: 0,
        })
      }
    },
    seleccionarFechaEmision() {
      if (this.data.moneda === 'USD') {
        this.getCambioSunat()
      } else {
        this.data.tipo_cambio = 1
      }
    },
    onRowSelected(items) {
      this.selectedDetraccion = items
    },
    onRowSelectedDoc(items) {
      this.selectedDoc = items
    },
    onRowSelectedAnt(items) {
      this.selectedAnt = items
    },
    seleccionarDetraccion() {
      if (this.selectedDetraccion) {
        this.data.porcentaje_detraccion = this.selectedDetraccion[0].porcentaje
        this.data.cod_producto_detraccion = this.selectedDetraccion[0].codigo
        this.porc_detraccion()
      }
    },
    seleccionarTipo() {
      this.filtrarSeries()
      if (this.data.tipo_doc !== '01') {
        this.data.is_anticipo = false
        this.data.is_deduccion = false
        if (this.data.detail.length > 0 && this.data.monto_total === 0) {
          this.data.detail = []
          this.detallevista = []
          this.data.monto_gravado = 0.00
          this.data.monto_total = 0.00
          this.data.monto_igv = 0.00
        }
      }
      if (this.data.tipo_doc === '07') {
        this.data.motivo_nota_credito = generalData.venta.notacredito[0].text
        this.data.codigo_nota_credito = generalData.venta.notacredito[0].value
      } else {
        this.data.motivo_nota_credito = ''
        this.data.codigo_nota_credito = ''
      }
      if (this.data.tipo_doc === '01' && this.data.doi_cliente.length === 8) {
        this.data.doi_cliente = ''
        this.data.razon_social_cliente = ''
        this.data.direccion_cliente = ''
        this.data.correo_cliente = ''
        this.data.tipo_doi_cliente = ''
        this.data.detail = []
        this.detallevista = []
        this.data.monto_gravado = 0.00
        this.data.monto_total = 0.00
        this.data.monto_igv = 0.00
      }
      if (this.data.tipo_doc === '03' && this.data.doi_cliente.length === 11) {
        this.data.doi_cliente = ''
        this.data.razon_social_cliente = ''
        this.data.direccion_cliente = ''
        this.data.correo_cliente = ''
        this.data.tipo_doi_cliente = ''
        this.data.detail = []
        this.detallevista = []
        this.data.monto_gravado = 0.00
        this.data.monto_total = 0.00
        this.data.monto_igv = 0.00
      }
    },
    cambioAnticipo() {
      if (this.data.is_deduccion) {
        this.data.is_deduccion = !this.data.is_deduccion
      }
      if (this.data.is_anticipo) {
        this.data.detail = []
        this.detallevista = []
        this.data.detail.push(
          {
            det_descripcion: 'PAGO POR ANTICIPO',
            det_codigo: 'PPA',
            det_cod_unidad_medida: 'NIU',
            det_cantidad: 1,
            det_valor_unitario: 0,
            det_igv: 0,
            det_precio: 0,
            det_aplicacion: 'GRAVADO',
          },
        )
        this.detallevista.push(
          {
            det_descripcion: 'PAGO POR ANTICIPO',
            det_codigo: 'PPA',
            det_cod_unidad_medida: 'UND',
            det_cantidad: 1,
            det_valor_unitario: 0,
            det_igv: 0,
            det_precio: 0,
            det_aplicacion: 'GRAVADO',
            det_subtotal: 0,
          },
        )
        this.data.monto_gravado = 0.00
        this.data.monto_total = 0.00
        this.data.monto_igv = 0.00
      } else {
        this.data.detail = []
        this.detallevista = []
        this.data.monto_gravado = 0.00
        this.data.monto_total = 0.00
        this.data.monto_igv = 0.00
      }
    },
    cambioDeduccion() {
      if (this.data.is_anticipo) {
        this.data.is_anticipo = !this.data.is_anticipo
      }
      this.data.serie_deduccion = ''
      this.data.correlativo_deduccion = ''
    },
    cambioDetraccion() {
      if (this.data.is_retencion) {
        this.data.is_retencion = !this.data.is_retencion
      }
      this.data.porcentaje_detraccion = 0
      this.data.detrac_total = 0
      this.data.constancia_detraccion = ''
      this.data.fecha_detraccion = moment().format('yyyy-MM-DD')
    },
    cambioRetencion() {
      if (this.data.is_detraccion) {
        this.data.is_detraccion = !this.data.is_detraccion
      }
      if (this.data.is_retencion) {
        this.data.reten_porc = 3
        this.data.monto_retencion = (this.data.monto_total * 3) / 100
      } else {
        this.data.reten_porc = 0
        this.data.monto_retencion = 0
      }
    },
    filtrarSeries() {
      this.series = []
      if (this.data.tipo_doc === '01') {
        this.series = this.seriesAll.filter(serieAll => serieAll.text.startsWith('F0'))
      } else if (this.data.tipo_doc === '03') {
        this.series = this.seriesAll.filter(serieAll => serieAll.text.startsWith('B'))
      } else if (this.data.tipo_doc === '07') {
        this.series = this.seriesAll.filter(serieAll => (serieAll.text.startsWith('FC')||serieAll.text.startsWith('BC')))
      } else {
        this.series = []
      }
      if (this.series.length > 0) {
        this.data.serie = this.series[0].text
        this.data.anexo = this.series[0].anexo
      } else {
        this.data.serie = ''
        this.data.anexo = ''
      }
    },
    filtrarSeriesNota() {
      this.seriesNota = []
      if (this.find.tipodoc === '01') {
        this.seriesNota = this.seriesAll2.filter(serieAll2 => serieAll2.text.startsWith('F0'))
      } else if (this.find.tipodoc === '03') {
        this.seriesNota = this.seriesAll2.filter(serieAll2 => serieAll2.text.startsWith('B'))
      } else if (this.find.tipodoc === '07') {
        this.seriesNota = this.seriesAll2.filter(serieAll2 => serieAll2.text.startsWith('FC')||serieAll.text.startsWith('BC'))
      } else {
        this.seriesNota = []
      }
      if (this.seriesNota.length > 0) {
        this.find.serie = this.seriesNota[0].text
      } else {
        this.find.serie = ''
      }
    },
    async findDocuments() {
      const request = {
        url: '/api/invoices/findDocument',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        data: this.find,
      }
      const resp = await store.dispatch('back/EXECUTE', request)
      this.documents = resp
      for (let i = 0; i < this.documents.length; i += 1) {
        this.documents[i].inv_fecha_emision = moment.utc(this.documents[i].inv_fecha_emision).format('DD-MM-yyyy')
      }
    },
    async findAnticipo() {
      const request = {
        url: '/api/invoices/findAnticipo',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        data: this.find,
      }
      const resp = await store.dispatch('back/EXECUTE', request)
      if (resp) {
        this.documents = resp
        for (let i = 0; i < this.documents.length; i += 1) {
          this.documents[i].inv_fecha_emision = moment.utc(this.documents[i].inv_fecha_emision).format('DD-MM-yyyy')
        }
      } else {
        this.documents = []
      }
    },
    seleccionarTipoNota() {
      this.filtrarSeriesNota()
    },
    seleccionarNotaCredito() {
      if (this.selectedDoc) {
        this.selectedCliente = {
          cNumeroRuc: this.selectedDoc[0].inv_doi_cliente,
          cRazonSocial: this.selectedDoc[0].inv_razon_social_cliente,
          cDireccion: this.selectedDoc[0].inv_direccion_cliente,
          cEmail: this.selectedDoc[0].inv_correo_cliente,
        }
        this.data.tipo_doi_cliente = this.selectedDoc[0].inv_tipo_doi_cliente
        this.data.doi_cliente = this.selectedDoc[0].inv_doi_cliente
        this.data.razon_social_cliente = this.selectedDoc[0].inv_razon_social_cliente
        this.data.direccion_cliente = this.selectedDoc[0].inv_direccion_cliente
        this.data.correo_cliente = this.selectedDoc[0].inv_correo_cliente
        this.data.serie_relacionado = this.selectedDoc[0].inv_serie
        this.data.tipo_doc_relacionado = this.selectedDoc[0].inv_tipo_doc
        this.data.correlativo_relacionado = this.selectedDoc[0].inv_correlativo
        this.data.monto_gravado = this.selectedDoc[0].inv_monto_gravado
        this.data.monto_exonerado = this.selectedDoc[0].inv_monto_exonerado
        this.data.monto_igv = this.selectedDoc[0].inv_monto_igv
        this.data.monto_total = this.selectedDoc[0].inv_monto_total
        this.data.arrayId = JSON.parse(this.selectedDoc[0].inv_pedidos)
        this.data.detail = []
        this.detallevista = []
        for (let i = 0; i < this.selectedDoc[0].detail.length; i += 1) {
          this.data.detail.push(
            {
              det_descripcion: this.selectedDoc[0].detail[i].det_descripcion,
              det_codigo: this.selectedDoc[0].detail[i].det_codigo,
              det_cod_unidad_medida: this.selectedDoc[0].detail[i].det_cod_unidad_medida,
              det_cantidad: this.selectedDoc[0].detail[i].det_cantidad,
              det_valor_unitario: this.selectedDoc[0].detail[i].det_valor_unitario,
              det_igv: this.selectedDoc[0].detail[i].det_igv,
              det_precio: this.selectedDoc[0].detail[i].det_precio,
              det_aplicacion: this.selectedDoc[0].detail[i].det_aplicacion,
            },
          )
          this.detallevista.push(
            {
              det_descripcion: this.selectedDoc[0].detail[i].det_descripcion,
              det_codigo: this.selectedDoc[0].detail[i].det_codigo,
              det_cod_unidad_medida: this.selectedDoc[0].detail[i].det_cod_unidad_medida,
              det_cantidad: this.selectedDoc[0].detail[i].det_cantidad,
              det_valor_unitario: this.selectedDoc[0].detail[i].det_valor_unitario,
              det_igv: this.selectedDoc[0].detail[i].det_igv,
              det_precio: this.selectedDoc[0].detail[i].det_precio,
              det_aplicacion: this.selectedDoc[0].detail[i].det_aplicacion,
              det_subtotal: (this.selectedDoc[0].detail[i].det_cantidad * this.selectedDoc[0].detail[i].det_precio).toFixed(2),
            },
          )
        }
      }
    },
    seleccionarMoneda() {
      if (this.data.moneda === 'USD') {
        this.showLoading = true
        this.getCambioSunat()
        this.showLoading = false
      } else if (this.data.moneda === 'PEN') {
        this.data.tipo_cambio = 1
      }
    },
    async seleccionarAnticipo() {
      if (this.selectedAnt) {
        this.data.serie_deduccion = this.selectedAnt[0].inv_serie
        this.data.correlativo_deduccion = this.selectedAnt[0].inv_correlativo

        // this.selectedCliente.iIdCliente = this.selectedAnt[0].inv_tipo_doi_cliente
        this.data.tipo_doi_cliente = this.selectedAnt[0].inv_tipo_doi_cliente

        await this.getClientes(this.selectedAnt[0].inv_doi_cliente)
        this.selectedCliente = {
          iIdCliente : this.clientes.length>0?this.clientes[0].iIdCliente:0,
          cNumeroRuc: this.selectedAnt[0].inv_doi_cliente,
          cRazonSocial: this.selectedAnt[0].inv_razon_social_cliente,
          cDireccion: this.selectedAnt[0].inv_direccion_cliente,
          cEmail: this.selectedAnt[0].inv_correo_cliente,
        }
        console.log('this.selectedAnt[0]',this.selectedAnt[0])
        this.data.doi_cliente = this.selectedAnt[0].inv_doi_cliente
        this.data.razon_social_cliente = this.selectedAnt[0].inv_razon_social_cliente
        this.data.direccion_cliente = this.selectedAnt[0].inv_direccion_cliente
        this.data.correo_cliente = this.selectedAnt[0].inv_correo_cliente
        this.data.serie_relacionado = this.selectedAnt[0].inv_serie
        this.data.tipo_doc_relacionado = "02"
        this.data.correlativo_relacionado = this.selectedAnt[0].inv_correlativo
        this.data.monto_gravado_deduccion = this.selectedAnt[0].inv_monto_gravado
        // this.data.monto_gravado = this.selectedAnt[0].inv_monto_gravado
        // this.data.monto_exonerado = this.selectedAnt[0].inv_monto_exonerado
        // this.data.monto_igv = this.selectedAnt[0].inv_monto_igv
        // this.data.monto_total = this.selectedAnt[0].inv_monto_total

        this.data.arrayId = JSON.parse(this.selectedAnt[0].inv_pedidos)
        this.data.detail = []
        this.detallevista = []
        // for (let i = 0; i < this.selectedAnt[0].detail.length; i += 1) {
        //   this.data.detail.push(
        //     {
        //       det_descripcion: this.selectedAnt[0].detail[i].det_descripcion,
        //       det_codigo: this.selectedAnt[0].detail[i].det_codigo,
        //       det_cod_unidad_medida: this.selectedAnt[0].detail[i].det_cod_unidad_medida,
        //       det_cantidad: this.selectedAnt[0].detail[i].det_cantidad,
        //       det_valor_unitario: this.selectedAnt[0].detail[i].det_valor_unitario,
        //       det_igv: this.selectedAnt[0].detail[i].det_igv,
        //       det_precio: this.selectedAnt[0].detail[i].det_precio,
        //       det_aplicacion: this.selectedAnt[0].detail[i].det_aplicacion,
        //     },
        //   )
        //   this.detallevista.push(
        //     {
        //       det_descripcion: this.selectedAnt[0].detail[i].det_descripcion,
        //       det_codigo: this.selectedAnt[0].detail[i].det_codigo,
        //       det_cod_unidad_medida: this.selectedAnt[0].detail[i].det_cod_unidad_medida,
        //       det_cantidad: this.selectedAnt[0].detail[i].det_cantidad,
        //       det_valor_unitario: this.selectedAnt[0].detail[i].det_valor_unitario,
        //       det_igv: this.selectedAnt[0].detail[i].det_igv,
        //       det_precio: this.selectedAnt[0].detail[i].det_precio,
        //       det_aplicacion: this.selectedAnt[0].detail[i].det_aplicacion,
        //       det_subtotal: (this.selectedAnt[0].detail[i].det_cantidad * this.selectedAnt[0].detail[i].det_precio).toFixed(2),
        //     },
        //   )
        // }
      }
    },
    doSometing() {
      this.showDialogOptions = false
    },
    seleccionarMotivo() {
      const motivo = this.motivos.find(element => element.value === this.data.codigo_nota_credito)
      this.data.motivo_nota_credito = motivo.text
    },
    seleccionarSerie() {
      const serie = this.series.find(element => element.value === this.data.serie)
      this.data.anexo = serie.anexo
    },
    calcularDetalle(row) {
      this.data.detail[0].det_descripcion = row.det_descripcion
      this.data.detail[0].det_valor_unitario = (row.det_precio / 1.18)
      this.data.detail[0].det_igv = (row.det_precio - (row.det_precio / 1.18))
      this.data.detail[0].det_precio = row.det_precio
      this.detallevista[0].det_descripcion = row.det_descripcion
      this.detallevista[0].det_valor_unitario = (row.det_precio / 1.18).toFixed(2)
      this.detallevista[0].det_igv = (row.det_precio - (row.det_precio / 1.18)).toFixed(2)
      this.detallevista[0].det_precio = (row.det_cantidad * row.det_precio).toFixed(2)
      this.detallevista[0].det_subtotal = (row.det_cantidad * row.det_precio).toFixed(2)
      this.data.monto_gravado = this.detallevista[0].det_valor_unitario
      this.data.monto_igv = this.detallevista[0].det_igv
      this.data.monto_total = this.detallevista[0].det_subtotal
    },
    calcularDetalleUnitario(row) {
      this.data.detail[0].det_valor_unitario = row.det_valor_unitario
      this.data.detail[0].det_igv = row.det_valor_unitario * 0.18
      this.data.detail[0].det_precio = row.det_valor_unitario * 1.18
      this.detallevista[0].det_valor_unitario = (Number(row.det_valor_unitario)).toFixed(2)
      this.detallevista[0].det_igv = (row.det_valor_unitario * 0.18).toFixed(2)
      this.detallevista[0].det_precio = (row.det_valor_unitario * 1.18).toFixed(2)
      this.detallevista[0].det_subtotal = (row.det_valor_unitario * 1.18).toFixed(2)
      this.data.monto_gravado = this.detallevista[0].det_valor_unitario
      this.data.monto_igv = this.detallevista[0].det_igv
      this.data.monto_total = this.detallevista[0].det_subtotal
    },
    sendMessage(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    },
    // eslint-disable-next-line consistent-return
    isNumberInt(evt) {
      // eslint-disable-next-line no-param-reassign
      evt = (evt) || window.event
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    // eslint-disable-next-line consistent-return
    isNumber(evt) {
      // eslint-disable-next-line no-param-reassign
      evt = (evt) || window.event
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.invoice-total-wrapper {
  width: 100%;
  max-width: 12rem;
  .invoice-total-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .invoice-total-title {
      margin-bottom: 0.35rem;
    }
    .invoice-total-amount {
      margin-bottom: 0.35rem;
      font-weight: 600;
    }
  }
}
</style>
